import './Footer.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { useAppContext, useAppStaticContext } from './AppContext';
import { Container } from './Container';
import { FooterContentType } from './contentTypes';
import { NewsletterForm } from './forms/NewsletterForm';
import { t } from './i18n/i18n';
import { ListAFromMenu } from './ListAFromMenu';
import { SocialIcons } from './SocialIcons';

export interface FooterProps {}

export const Footer = (props: FooterProps) => {
  const footerMenu = b2x.useMenu('MENU_FOOTER');
  const footerBottomMenu = b2x.useMenu('MENU_BOTTOM_FOOTER');
  const footerContent = b2x.useContent<FooterContentType>('FOOTER_CONTENT');

  const { greyBackgroundPage, hideNewsletterMobile, minimalFooter } = useAppContext();
  const { setFooterCopyrightRef, showAccountOffcanvas } = useAppStaticContext();

  const { session } = b2x.useAppContext();

  return (
    <footer className="footer">
      {!minimalFooter && (
        <>
          <div className="bg-white">
            {/* {!greyBackgroundPage && <hr className="footer-hr" />} */}
            <Container>
              <div className="px-lg-3 py-lg-5">
                <b2x.Row className="justify-content-center">
                  <b2x.Col size={{ xl: 11, xs: 12 }}>
                    <b2x.Row>
                      <b2x.Col
                        className={classnames('order-lg-2', hideNewsletterMobile ? 'd-none d-lg-block' : '')}
                        size={{ lg: 5, xl: 4, xs: 12 }}
                      >
                        <div className="ps-lg-4 p-3 p-lg-0 text-start">
                          <div className="footerNewsletter">
                            <h6 className="text-uppercase fw-semi-bold primary-font">{t('misc.newsletterTitle')}</h6>
                            <div className="small pb-2">{b2x.formatHtml(footerContent?.body.newsletterSubtitle)}</div>
                            <NewsletterForm className="mb-4" emailFieldStyle="inputGroup" source="website-footer" />
                          </div>
                          <div className="footerPaymentIcons d-none d-lg-block py-lg-2">
                            <h6 className="text-uppercase fw-semi-bold primary-font">{t('misc.payments')}</h6>
                            <b2x.PaymentIcons justifyContent="start" />
                          </div>
                        </div>
                      </b2x.Col>
                      <b2x.Col size={{ lg: 7, xl: 8, xs: 12 }}>
                        <div className="d-block d-lg-none py-3 py-lg-2 bg-secondary text-primary ">
                          <SocialIcons />
                        </div>
                        <b2x.Accordion columnLayoutFrom="lg" id={'accordionExample'} itemBodyClassName="pt-0">
                          {footerMenu?.children.map(
                            (column) =>
                              column.label && (
                                <b2x.AccordionItem
                                  className={classnames({
                                    'd-lg-none': column.tags?.includes('mobile-only'),
                                  })}
                                  id={`accordionItem-${column.id}`}
                                  key={column.code}
                                  title={column.label}
                                >
                                  {column.children.map((li) =>
                                    li.code === 'SITE_CCN_AREA' ? (
                                      session?.userLogged ? (
                                        li.children.map(
                                          (subLi) =>
                                            subLi.link && (
                                              <div key={subLi.code}>
                                                <b2x.router.Link
                                                  className="text-reset text-decoration-none small d-block py-1"
                                                  to={subLi.link}
                                                >
                                                  {subLi.label}
                                                </b2x.router.Link>
                                              </div>
                                            )
                                        )
                                      ) : (
                                        <div key={li.code}>
                                          <b2x.BlankButton
                                            className="small d-block py-1"
                                            onClick={showAccountOffcanvas}
                                          >
                                            {t('account.login')}
                                          </b2x.BlankButton>
                                        </div>
                                      )
                                    ) : (
                                      li.link && (
                                        <div key={li.code}>
                                          {li.image ? (
                                            <b2x.router.Link className={'mt-2 d-inline-block'} to={li.link}>
                                              <b2x.Image {...li.image} fluid width={100} />
                                            </b2x.router.Link>
                                          ) : (
                                            <b2x.router.Link
                                              className="text-reset text-decoration-none small d-block py-1"
                                              to={li.link}
                                            >
                                              {li.label}
                                            </b2x.router.Link>
                                          )}
                                        </div>
                                      )
                                    )
                                  )}
                                </b2x.AccordionItem>
                              )
                          )}
                        </b2x.Accordion>
                      </b2x.Col>
                    </b2x.Row>
                  </b2x.Col>
                </b2x.Row>
              </div>
            </Container>
          </div>

          <div className="py-3 py-lg-2 bg-secondary text-primary d-none d-lg-block">
            <SocialIcons />
          </div>
          <div className="footerPaymentIcons d-block d-lg-none pt-3">
            <b2x.PaymentIcons justifyContent="center" />
          </div>
        </>
      )}
      <div
        className={classnames(greyBackgroundPage && minimalFooter ? 'bg-white' : 'bg-lighter')}
        ref={setFooterCopyrightRef}
      >
        <Container>
          <b2x.Row className="align-items-center">
            <b2x.Col className="order-lg-2" size={{ lg: 6, xs: 12 }}>
              <div className="d-none d-lg-block text-dark my-2 lh-1">
                {footerBottomMenu && <ListAFromMenu menu={footerBottomMenu} textUppercase />}
              </div>
            </b2x.Col>
            <b2x.Col size={{ lg: 6, xs: 12 }}>
              <div className="footerCopyright px-3 px-lg-0 my-2">
                {footerContent?.body.copyright && (
                  <small className="extra-small">{b2x.formatHtml(footerContent.body.copyright)}</small>
                )}
              </div>
            </b2x.Col>
          </b2x.Row>
        </Container>
      </div>
    </footer>
  );
};
